import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[formControlName][inputChange]',
    standalone: false
})
export class InputChangeDirective {

  constructor(public ngControl: NgControl) {}

  @HostListener('input', ['$event'])
  onModelChange(event) {
     this.onInputChange(event.target.value);
  }

  onInputChange(value: any) {
    this.ngControl.control.setValue(value);
    this.ngControl.valueAccessor.writeValue(value);
  }

}
