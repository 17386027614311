import { TestBootstrapper } from '../app/bootstrapping/test.bootstrapper';

export const environment = {
	production: false,
	enableTracing: false,
	bootstrapper: TestBootstrapper,
	environmentName: 'Test',
	sentryDsn: 'https://32dc518847ae44e894275c5368e0cef9@o4505187202760704.ingest.sentry.io/4505187207086080',
	moduleFederation: {
		selfServiceHost: 'https://home.froda-dev.se/helix/selfservice/',
	},
};
